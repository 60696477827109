import React from "react"
import {
  Twitter,
  Discord,
  Slack,
  LinkedIn,
  GitHub,
  Email,
} from "../components/Icons"
import styles from "./kontakt.module.css"

import Bio from "../components/Bio"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const Contact = ({ location }) => (
  <Layout location={location} title="Kontakt">
    <SEO
      title="Kontakt"
      keywords={[`kontakt`, "email", "twitter", "github", "discord", "slack"]}
    />
    <article className="page">
      <h1>Kontakt</h1>
      <section className={styles.myself}>
        <div className={styles.myselfHelp}>
          Jeśli tylko wpadła Ci do głowy myśl napisania do mnie — zrób to! Twój głos ma znaczenie.
        </div>
        <div className={styles.socials}>
          <div>
            <a href="https://twitter.com/pr0gramista">
              <Twitter className={styles.icon} /> @pr0gramista
            </a>
          </div>
          <div>
            <a href="https://github.com/pr0gramista">
              <GitHub className={styles.icon} /> pr0gramista
            </a>
          </div>
          <div>
            <a href="https://www.linkedin.com/in/pr0gramista/">
              <LinkedIn className={styles.icon} /> pr0gramista
            </a>
          </div>
          <div>
            <a href="mailto:kontakt@pr0gramista.pl">
              <Email className={styles.icon} /> kontakt@pr0gramista.pl
            </a>
          </div>
        </div>
      </section>
      <section className={styles.public}>
        <div className={styles.publicHeading}>
          Możesz też wpaść na naszego polskiego programistycznego
          <a href="https://discord.gg/YnATqU7" > <Discord inline /> <span className={styles.publicLink}>Discorda</span> </a>
          czy
          <a href="http://slackin.devstyle.pl" > <Slack inline /> <span className={styles.publicLink}>Slacka</span></a>.
        </div>
      </section>
    </article>
    <Bio />
  </Layout>
)

export default Contact
